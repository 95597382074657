

.grid-container{
  display: grid;
  grid-template-areas:
  "header"
  "main"
  "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 7rem 1fr 3rem;
  height: 100%;
}

#container { display: flex; flex-direction: column; }
#down { flex-grow: 1; }

.header{
  grid-area: header;
  background-color: #130620;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
}

.header-right{
  margin-left: auto;
  align-items: center;
  font-size: 1vw;
  justify-content: center;
}

.brand a{
  color: #ffffff;
  font-size: 2.8vw;
  font-weight: bold;
  text-decoration: none;
}

.header-side{
  padding-left: 4rem;
  font-size: 1.2vw;
  padding-top: 0.9vw;
}

.main {
  grid-area: main;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to right, #c954ff, rgb(221, 17, 221), #6a82fb);
}

.footer{
  grid-area: footer;
  background-color: #130620;
  color: #ffffff;
  display: flex;
  justify-content: center;
  font-size: 1vw;
  align-items: center;
}

.content{
  display: flex;
  flex-grow: 1;
}

/*Home Screen*/
.home{
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

}
.menu-list{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu-list a {
  padding: 0rem;
  margin: 1.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: white;
}

.menu-list li
{
  display: flex;
  list-style-type: none;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 15vh;
}

.menu-list p {
  font-size: 2vw;
}

.section a {
  color: #ffffff;
  font-weight: bold;
  font-size: 2vw;
  text-decoration: none;
}

.about {
  background-image: linear-gradient(to right, cyan, magenta);
}

.contacts {
  background-image: linear-gradient(to right, fuchsia, turquoise);
}

.projects{
  background-image: linear-gradient(to right,  turquoise, fuchsia);

}
.question-box {
  background-image: linear-gradient(to right, magenta, aqua);
}

.home-main-image{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 45%;
}
.home-main-image img{

  max-width: 80%;
}

.fade-in {
  animation: fadeIn ease 2s;

}
.button{
  background-image: linear-gradient(to right, cyan, magenta);
  border: none;
  text-align: center;
  color: white;
  text-decoration: none;
  padding: .8vw;
  font-size: 1vw;
  font-weight: bold;
}
.fixed-bottom{
  position: fixed;
  bottom:2%;
  right:2%;
  padding: 0%;
  padding-right: 1%;
  padding-left: 1%;
  font-size: auto;

}
/*About*/
.corp-list{
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
}

.logo-list{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  padding-bottom: 10rem;
}

.about-list{
  width: 37rem;
  max-height: 30rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 10rem;
}

.logo-list img {
  margin-right: 10rem;
  margin-top: 3rem;
}

.logo {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 200px;
}

.text-space{
  text-align: center;
  padding-bottom: 35rem;
  padding-top: 35rem;
}

.paragraph {
  font-size: 1.8vw;
  padding-left: 1rem;
}

.bold {
  font-size: 2.5vw;
}

.title {
  font-size: 5rem;
}


.list-intro {
  font-size: 2vw;
  padding-left: 2rem;
  padding-right: 2rem;
}

.table-intro {
  margin-bottom: 3rem;
}
@media only screen and (max-width: 740px) {
  .title{
    font-size: 1.6vw;
  }
}
.paragraph-group{
  padding-bottom: 35rem;

}
.paragraph-limiter{
  padding-left: 4vh;
  padding-right: 4vh;
}

.centered{
  justify-content: center;
  text-align: center;
}

.p-with-address{
  display: inline-block;
}

.justify-center{
  text-align: center;
  text-align-last: center;
}

.contact-title-section{
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.padding-list{
 padding-left: 2rem;
}
.red{
  color: red;
  text-decoration: underline;
}
.table-contact{
  width: 100%;
  font-size: 1.5vw;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: white;
  border-collapse: collapse;
}

th, td{
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid white;

}

td:not(:last-child), th:not(:last-child)
{
  
  border-right: 1px solid white;
}


th {
  background-color: rgba(255, 255, 255, 0.1);
}
.table-contact a:hover{
  color: #ffc107;
}
.table-contact  a{
  color: white;
  transition: color 0.3s;
}

.project-table {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 10rem;
  margin-bottom: 12rem;
}
.question-title-section{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 4rem;
}
.form{
  align-self: center;
  flex-grow: 1;
  width: 94.5%;
  height: 100%;
  padding-bottom: 10vh;
}
.email{
  width: 100%;
  margin-bottom: 1rem;
}
.question-text-area{
  width: 100%;
  height: 90%;
  font-size: 2vh;
  margin-bottom: 1rem;
}

.send-btn{
  align-self:flex-end;
  justify-self: flex-end;
}

.text-centered {
  display: flex;
  justify-content: center;
}


@keyframes fadeIn {
  from {transform: translateY(-10rem); opacity: 0;}
  to {transform: translateY(0); opacity: 1;}
}

@media only screen and (max-width: 768px) {
  .grid-container{
    display: grid;
    grid-template-areas:
    "header"
    "main"
    "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 3rem 1fr 2rem;
    height: 100%;
  }

  .header, .footer{
    padding: 1vw;
  }

  .footer {
    font-size: 4vw;
  }
  .brand a{
    font-size: 3vw;
  }

  .header-side{
    padding-left: 2rem;
    font-size: 2vw;
    padding-top: 0.5vw;
  }

  .menu-list li
  {
    width: 80vw;
    height: 10vh;
  }

  .menu-list p {
    font-size: 4vw;
  }

  .section a {
    font-size: 4vw;
  }

  .home-main-image{
    max-width: 90%;
  }

  .home-main-image img{
    max-width: 90%;
  }

  .button{
    padding: 2vw;
    font-size: 3vw;
  }

  .about-list{
    width: 90vw;
    max-height: 20rem;
  }

  .logo-list img {
    margin-right: 5rem;
    margin-top: 1.5rem;
  }

  .paragraph {
    font-size: 4vw;
    padding-left: 0.5rem;
  }

  .bold {
    font-size: 5vw;
  }

  .title {
    font-size: 2rem;
  }

  .list-intro {
    font-size: 4vw;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .paragraph-group{
    padding-bottom: 20rem;
  }

  .paragraph-limiter{
    padding-left: 2vh;
    padding-right: 2vh;
  }

  .p-with-address{
    display: block;
  }

  .contact-title-section{
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  .padding-list{
   padding-left: 1rem;
  }

  .table-contact{
    font-size: 3vw;
  }

  th, td{
    padding: 5px;
  }

  .project-table {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 5rem;
    margin-bottom: 6rem;
  }

  .form{
    width: 90%;
    padding-bottom: 5vh;
  }

  .email{
    margin-bottom: 0.5rem;
  }

  .question-text-area{
    font-size: 4vh;
    margin-bottom: 0.5rem;
  }
}
